import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

function createParagraphMarkup(text) {
  return { __html: text }
}

class OffsetHeadingCTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
<>
            <div className="columns">
            <div className={`column is-${this.props.headingSideColumnIs}`}></div>
            <div className="column">
              {this.props.headingJSX}
            </div>
          </div>
          <CTA
            className={this.props.className ? this.props.className : ""}
            sideColumnIs={this.props.sideColumnIs}
            textColumns={
              <div className="column">
              {this.props.paragraphJSX}
              </div>
            }
            buttonColumns={
              <div className="column">
                <Button
                  href={this.props.buttonUrl}
                  buttonText={this.props.buttonText}
                />
              </div>
            }
          />
</>
    )
  }
}

OffsetHeadingCTA.propTypes = {
  headingJSX: PropTypes.element,
  paragraphJSX: PropTypes.element,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
}

export default OffsetHeadingCTA
