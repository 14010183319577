import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from '@bit/azheng.joshua-tree.button';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class TextPhoto extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {


    return (
        <div className={`columns ${this.props.columnsClasses}`}>
            <div className={`column is-${this.props.sideColumnIs}`}></div>
            {this.props.leftColumn}
            <div className={`column is-${this.props.centerColumnIs}`}>
            </div>
            {this.props.rightColumn}
            <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
    )
  }
}

TextPhoto.propTypes = {
  leftColumn: PropTypes.element,
  rightColumn: PropTypes.element,
  centerColumnIs: PropTypes.number,
  sideColumnIs: PropTypes.number,
}

export default TextPhoto
