/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from "react" 
import ImageMeta from "../components/ImageMeta"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import TextPhoto from "../components/TextPhoto/TextPhoto"
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import SEO from "../components/seo"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

export default ({ data }) => {

  const post = data.allUniquePagesJson.nodes[0];

  return (
    <SharedStateProvider>
      <Layout pageTitle="first-visit">
      <SEO title={post.metaTitle} description={post.metaDescription} />
        <section className="joshua-tree-content top-section section green-color-back text-section is-mobile">
          <div className="columns">
            <div className="column is-7"></div>
            <div className="column">
              <h1>{post.heading}</h1>
            </div>
          </div>

          <div className="columns">
            <div className="column is-4"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}>
            </div>
            <div className="column is-3"></div>
          </div>
        </section>

        <div className="joshua-tree-content">
          <section className="body-sections section" style={{ paddingTop: "80px" }}>
            <TextPhoto
              columnsClasses="is-vcentered reverse-columns"
              sideColumnIs={2}
              centerColumnIs={1}
              leftColumn={
                <div className="column is-9">
                  <h2>{post.prepareFirstAppointment.heading}</h2>
                  <div style={{marginTop: "1.625rem"}} dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.prepareFirstAppointment.text)
                  )}>
            </div>
                  <ButtonGroup alignRight={true}>
                    <Button
                      buttonText={post.prepareFirstAppointment.buttonOne.buttonText}
                      href={post.prepareFirstAppointment.buttonOne.href}
                    />
                    <Button
                      external
                      buttonText={post.prepareFirstAppointment.buttonTwo.buttonText}
                      href={post.prepareFirstAppointment.buttonTwo.href}
                    />
                  </ButtonGroup>
                </div>
              }
              rightColumn={
                <div className="column is-10">
                  <ImageMeta
                    cloudName="nuvolum"
                    className="is-fullwidth-mobile"
                    publicId={post.prepareFirstAppointment.imageId}
                    responsive
                    width="auto"
                  >
                    
                  </ImageMeta>
                </div>
              }
            />
          </section>
          <section className="body-sections section">
            <TextPhoto
              columnsClasses="is-vcentered"
              sideColumnIs={2}
              centerColumnIs={1}
              leftColumn={
                <div className="column is-10">
                  <ImageMeta
                    cloudName="nuvolum"
                    className="is-fullwidth-mobile"
                    publicId={post.itemsToBring.imageId}
                    responsive
                    width="auto"
                  >
                    
                  </ImageMeta>
                </div>
              }
              rightColumn={
                <div className="column is-9">
                  <h3>{post.itemsToBring.heading}</h3>
                  <div style={{marginTop: "1.625rem"}} dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.itemsToBring.text)
                  )}>
            </div>
                </div>
              }
            />
          </section>
          
          <section className="body-sections section">
          <div className="columns">
            <div className="column is-5"></div>
            <div className="column">
              <h3 style={{marginBottom: "1.5rem"}}>{post.whatToExpect.heading}</h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.whatToExpect.text)
                  )}>
            </div>
            <div className="column is-4"></div>
          </div>
          </section>

          <section className="body-sections section color-back">
          <div className="columns">
            <div className="column is-5"></div>
            <div className="column">
              <h3 style={{marginBottom: "1.5rem"}}>{post.financialInsurancePolicy.heading}</h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.financialInsurancePolicy.text)
                  )}>
            </div>
            <div className="column is-4"></div>
          </div>
          </section>

<section className="body-sections section" style={{paddingTop: "80px", paddingBottom: "30px" }}>
              <OffsetHeadingCTA
                headingSideColumnIs={5}
                headingJSX={<h3 style={{marginBottom: "1.5rem"}}>{post.getStarted.heading}</h3>}
                sideColumnIs={4}
                paragraphJSX={<div dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(post.getStarted.blurb)
                )}></div>}
                buttonText={post.getStarted.buttonText}
                buttonUrl={post.getStarted.href}
              />
</section>

        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query yourFirstVisitPage ($loneUrl: String!)  {
  allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
    nodes {
      heading
      topBlurb
      metaTitle
      metaDescription
      title
      financialInsurancePolicy {
        heading
        text
      }
      prepareFirstAppointment {
        imageId
        text
        heading
        buttonOne {
          buttonText
          href
        }
        buttonTwo {
          buttonText
          href
        }
      }
      itemsToBring {
        heading
        imageId
        text
      }
      whatToExpect {
        heading
        text
      }
      getStarted {
        blurb
        buttonText
        heading
        href
        subheading
      }
    }
  }
}

`
